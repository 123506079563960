import { Box } from '@mui/material';
import { KYC_OPS_ROLE, PRODUCT_BUSINESS_OPS, SPECIALIST, SUPER_ADMIN_ROLE, CUSTOMER_SERVICE_OPS, SUPPORT_ADMIN, FINANCE_OPS, FINANCE_ADMIN, CUSTOMER_OPS_SPV, TECH_TEAM, GS_PRODUCT_TEAM, SALES_KYC, KYC_SPV_ROLE } from '@src/utils/constants';
export const INVALID_NUMERIC_CHARACTERS = ['e', 'E', '-', '+'];
export const INVALID_NUMERIC_CHARACTERS_ONLY_DIGIT = ['e', 'E', '-', '+', '.', ','];
export const NUMBER_FORMATTING_REGEX = /\B(?=(\d{3})+(?!\d))/g;
export const DEFAULT_ERROR_MESSAGE = 'Something goes wrong, please try later.';
export const SUPPORT_IMAGE_TYPES_FILES = ['image/jpg', 'image/jpeg', 'image/png'];
export const DEFAULT_UNSAVED_CHANGES_MESSAGE = 'You have unsaved changes. Are you sure you want to leave?';
export const DEFAULT_SUCCESS_SUBMIT_MANUAL_KYC = 'Kyc verified successfully';
export const INDONESIAN_COUNTRY_CODE = '62';
export const TOAST_ICONS = {
  success: <Box>
      <img src="/assets/images/filled_success_icon.png" alt="success" width={42} height={42} />
    </Box>,
  error: <Box>
      <img src="/assets/images/warning_icon.png" alt="error" width={42} height={42} />
    </Box>,
  warning: <Box>
      <img src="/assets/images/warning_icon.png" alt="error" width={42} height={42} />
    </Box>
};
export const TOAST_TITLE = {
  success: 'Success!',
  info: 'Info!',
  error: 'Failed!',
  default: 'Info!',
  warning: 'Warning!'
};

// NB: The login, logout and no-permission page doesn't require authorization token.
export const NON_AUTHENTICATED_PATHS = ['/', '/logout', '/no-permission', '/error', '/color-guide'];

// NB: List of all permissions
export enum ALL_PERMISSIONS {
  // auction-management
  AUCTION_MANAGEMENT_ONGOING_LIST = 'AUCTION_MANAGEMENT_ONGOING_LIST',
  AUCTION_MANAGEMENT_ONGOING_DETAIL = 'AUCTION_MANAGEMENT_ONGOING_DETAIL',
  AUCTION_MANAGEMENT_ONGOING_EDIT = 'AUCTION_MANAGEMENT_ONGOING_EDIT',
  AUCTION_MANAGEMENT_FEATURED_LIST = 'AUCTION_MANAGEMENT_FEATURED_LIST',
  AUCTION_MANAGEMENT_FEATURED_DETAIL = 'AUCTION_MANAGEMENT_FEATURED_DETAIL',
  AUCTION_MANAGEMENT_FEATURED_EDIT = 'AUCTION_MANAGEMENT_FEATURED_EDIT',
  AUCTION_MANAGEMENT_PAST_LIST = 'AUCTION_MANAGEMENT_PAST_LIST',
  AUCTION_MANAGEMENT_PAST_DETAIL = 'AUCTION_MANAGEMENT_PAST_DETAIL',
  AUCTION_MANAGEMENT_PAST_EDIT = 'AUCTION_MANAGEMENT_PAST_EDIT',
  AUCTION_MANAGEMENT_CREATE = 'AUCTION_MANAGEMENT_CREATE',
  AUCTION_MANAGEMENT_TERMINATE_AUCTION = 'AUCTION_MANAGEMENT_TERMINATE_AUCTION',
  // balance-adjustment
  BALANCE_ADJUSTMENT_CREATE = 'BALANCE_ADJUSTMENT_CREATE',
  BALANCE_ADJUSTMENT_EDIT = 'BALANCE_ADJUSTMENT_EDIT',
  BALANCE_ADJUSTMENT_DOWNLOAD = 'BALANCE_ADJUSTMENT_DOWNLOAD',
  BALANCE_ADJUSTMENT_LIST = 'BALANCE_ADJUSTMENT_LIST',
  BALANCE_ADJUSTMENT_DETAIL_VIEW = 'BALANCE_ADJUSTMENT_DETAIL_VIEW',
  // banner
  HOMEPAGE_BANNER_LIST = 'HOMEPAGE_BANNER_LIST',
  HOMEPAGE_BANNER_VIEW_DETAIL = 'HOMEPAGE_BANNER_VIEW_DETAIL',
  HOMEPAGE_BANNER_REORDER = 'HOMEPAGE_BANNER_REORDER',
  HOMEPAGE_BANNER_SHOW = 'HOMEPAGE_BANNER_SHOW',
  HOMEPAGE_BANNER_EDIT = 'HOMEPAGE_BANNER_EDIT',
  HOMEPAGE_BANNER_REMOVE = 'HOMEPAGE_BANNER_REMOVE',
  HOMEPAGE_BANNER_ADD = 'HOMEPAGE_BANNER_ADD',
  // translation
  TRANSLATION_DOWNLOAD = 'TRANSLATION_DOWNLOAD',
  TRANSLATION_UPLOAD = 'TRANSLATION_UPLOAD',
  // stock-category
  STOCK_CATEGORY_LIST = 'STOCK_CATEGORY_LIST',
  STOCK_CATEGORY_ADD = 'STOCK_CATEGORY_ADD',
  STOCK_CATEGORY_MANAGE = 'STOCK_CATEGORY_MANAGE',
  STOCK_CATEGORY_DELETE = 'STOCK_CATEGORY_DELETE',
  // cash-out
  CASH_OUT_LIST = 'CASH_OUT_LIST',
  CASH_OUT_APPROVE = 'CASH_OUT_APPROVE',
  CASH_OUT_REJECT = 'CASH_OUT_REJECT',
  // cryto_category
  CRYPTO_CATEGORY_LIST = 'CRYPTO_CATEGORY_LIST',
  CRYPTO_CATEGORY_ADD = 'CRYPTO_CATEGORY_ADD',
  CRYPTO_CATEGORY_MANAGE = 'CRYPTO_CATEGORY_MANAGE',
  CRYPTO_CATEGORY_DELETE = 'CRYPTO_CATEGORY_DELETE',
  // withdrawal-management
  WITHDRAWAL_MANAGEMENT_LIST = 'WITHDRAWAL_MANAGEMENT_LIST',
  WITHDRAWAL_MANAGEMENT_VIEW_DETAIL = 'WITHDRAWAL_MANAGEMENT_VIEW_DETAIL',
  WITHDRAWAL_MANAGEMENT_ADD = 'WITHDRAWAL_MANAGEMENT_ADD',
  WITHDRAWAL_MANAGEMENT_EDIT = 'WITHDRAWAL_MANAGEMENT_EDIT',
  WITHDRAWAL_MANAGEMENT_DELETE = 'WITHDRAWAL_MANAGEMENT_DELETE',
  WITHDRAWAL_MANAGEMENT_DOWNLOAD = 'WITHDRAWAL_MANAGEMENT_DOWNLOAD',
  WITHDRAWAL_MANAGEMENT_APPROVAL = 'WITHDRAWAL_MANAGEMENT_APPROVAL',
  // kyc-admin-panel
  KYC_ADMIN_PANEL_LIST = 'KYC_ADMIN_PANEL_LIST',
  KYC_ADMIN_PANEL_SUMMARY = 'KYC_ADMIN_PANEL_SUMMARY',
  KYC_ADMIN_PANEL_TAG_USER = 'KYC_ADMIN_PANEL_TAG_USER',
  KYC_ADMIN_PANEL_VIEW_DETAIL = 'KYC_ADMIN_PANEL_VIEW_DETAIL',
  KYC_ADMIN_PANEL_UPDATE_KTP = 'KYC_ADMIN_PANEL_UPDATE_KTP',
  KYC_ADMIN_PANEL_SEND_VERIFICATION_EMAIL = 'KYC_ADMIN_PANEL_SEND_VERIFICATION_EMAIL',
  KYC_ADMIN_PANEL_RESET_KYC_COUNTER = 'KYC_ADMIN_PANEL_RESET_KYC_COUNTER',
  KYC_ADMIN_PANEL_APPROVE_OR_REJECT = 'KYC_ADMIN_PANEL_APPROVE_OR_REJECT',
  KYC_ADMIN_PANEL_ADD_NOTE = 'KYC_ADMIN_PANEL_ADD_NOTE',
  KYC_ADMIN_PANEL_REQUEST_CHANGE = 'KYC_ADMIN_PANEL_REQUEST_CHANGE',
  KYC_ADMIN_PANEL_VALIDATE_CHANGE = 'KYC_ADMIN_PANEL_VALIDATE_CHANGE',
  KYC_ADMIN_PANEL_KYC_HISTORY_LIST = 'KYC_ADMIN_PANEL_KYC_HISTORY_LIST',
  KYC_ADMIN_PANEL_LOCK_ACCOUNT = 'KYC_ADMIN_PANEL_LOCK_ACCOUNT',
  KYC_ADMIN_PANEL_ADDITIONAL_KYC_LIST = 'KYC_ADMIN_PANEL_ADDITIONAL_KYC_LIST',
  KYC_ADMIN_PANEL_USER_DETAILS_KYC_LIST = 'KYC_ADMIN_PANEL_USER_DETAILS_KYC_LIST',
  KYC_ADMIN_PANEL_KYC_FORCE_VERIFY_EMAIL_BUTTON = 'KYC_ADMIN_PANEL_KYC_FORCE_VERIFY_EMAIL_BUTTON',
  KYC_ADMIN_PANEL_KYC_FORCE_VERIFY_CONTINUE_BUTTON = 'KYC_ADMIN_PANEL_KYC_FORCE_VERIFY_CONTINUE_BUTTON',
  KYC_ADMIN_PANEL_KYC_FORCE_VERIFY_KYC_BUTTON = 'KYC_ADMIN_PANEL_KYC_FORCE_VERIFY_KYC_BUTTON',
  // user-management
  USER_MANAGEMENT_USER_LIST = 'USER_MANAGEMENT_USER_LIST',
  USER_MANAGEMENT_EDIT_NAME = 'USER_MANAGEMENT_EDIT_NAME',
  USER_MANAGEMENT_VIEW_USER_HOLDING_DETAIL = 'USER_MANAGEMENT_VIEW_USER_HOLDING_DETAIL',
  USER_MANAGEMENT_EDIT_ALPACA_ID = 'USER_MANAGEMENT_EDIT_ALPACA_ID',
  USER_MANAGEMENT_UPDATE_PROFILE = 'USER_MANAGEMENT_UPDATE_PROFILE',
  USER_MANAGEMENT_LOCK_ACCOUNT = 'USER_MANAGEMENT_LOCK_ACCOUNT',
  USER_MANAGEMENT_RISK_STATUS = 'USER_MANAGEMENT_RISK_STATUS',
  USER_MANAGEMENT_RESET_PIN = 'USER_MANAGEMENT_RESET_PIN',
  USER_MANAGEMENT_ENABLE_REFERRAL_CODE = 'USER_MANAGEMENT_ENABLE_REFERRAL_CODE',
  USER_MANAGEMENT_DISABLE_REFERRAL_CODE = 'USER_MANAGEMENT_DISABLE_REFERRAL_CODE',
  USER_MANAGEMENT_LIST_OF_TRANSACTION = 'USER_MANAGEMENT_LIST_OF_TRANSACTION',
  USER_MANAGEMENT_TRANSACTION_DOWNLOAD = 'USER_MANAGEMENT_TRANSACTION_DOWNLOAD',
  USER_MANAGEMENT_DETAILED_KYC_VIEW = 'USER_MANAGEMENT_DETAILED_KYC_VIEW',
  USER_MANAGEMENT_SEND_VERIFICATION_EMAIL = 'USER_MANAGEMENT_SEND_VERIFICATION_EMAIL',
  USER_MANAGEMENT_RESET_KYC_COUNTER = 'USER_MANAGEMENT_RESET_KYC_COUNTER',
  USER_MANAGEMENT_APPROVE_OR_REJECT = 'USER_MANAGEMENT_APPROVE_OR_REJECT',
  USER_MANAGEMENT_ADD_NOTES = 'USER_MANAGEMENT_ADD_NOTES',
  USER_MANAGEMENT_REWARD_HISTORY = 'USER_MANAGEMENT_REWARD_HISTORY',
  USER_MANAGEMENT_RESET_2FA = 'USER_MANAGEMENT_RESET_2FA',
  USER_MANAGEMENT_KYC_REQUEST_CHANGE = 'USER_MANAGEMENT_KYC_REQUEST_CHANGE',
  USER_MANAGEMENT_KYC_VALIDATE_CHANGE = 'USER_MANAGEMENT_KYC_VALIDATE_CHANGE',
  USER_MANAGEMENT_KYC_UPDATE_KTP = 'USER_MANAGEMENT_KYC_UPDATE_KTP',
  USER_MANAGEMENT_KYC_HISTORY_LIST = 'USER_MANAGEMENT_KYC_HISTORY_LIST',
  USER_MANAGEMENT_RISK_MONITORING = 'USER_MANAGEMENT_RISK_MONITORING',
  USER_MANAGEMENT_UNLOCK_TREATMENT = 'USER_MANAGEMENT_UNLOCK_TREATMENT',
  USER_MANAGEMENT_ADD_RISK_NOTES = 'USER_MANAGEMENT_ADD_RISK_NOTES',
  USER_MANAGEMENT_ADDITIONAL_KYC_LIST = 'USER_MANAGEMENT_ADDITIONAL_KYC_LIST',
  USER_MANAGEMENT_USER_DETAILS_KYC_LIST = 'USER_MANAGEMENT_USER_DETAILS_KYC_LIST',
  USER_MANAGEMENT_USER_DETAILS_FORCE_VERIFY_EMAIL_BUTTON = 'USER_MANAGEMENT_USER_DETAILS_FORCE_VERIFY_EMAIL_BUTTON',
  USER_MANAGEMENT_KYC_DETAILS_FORCE_VERIFY_EMAIL_BUTTON = 'USER_MANAGEMENT_KYC_DETAILS_FORCE_VERIFY_EMAIL_BUTTON',
  USER_MANAGEMENT_KYC_FORCE_VERIFY_CONTINUE_BUTTON = 'USER_MANAGEMENT_KYC_FORCE_VERIFY_CONTINUE_BUTTON',
  USER_MANAGEMENT_KYC_FORCE_VERIFY_KYC_BUTTON = 'USER_MANAGEMENT_KYC_FORCE_VERIFY_KYC_BUTTON',
  // refferal-code
  REFERRAL_CODE_LIST = 'REFERRAL_CODE_LIST',
  REFERRAL_CODE_CREATE = 'REFERRAL_CODE_CREATE',
  REFERRAL_CODE_DOWNLOAD = 'REFERRAL_CODE_DOWNLOAD',
  REFERRAL_CODE_MANAGE = 'REFERRAL_CODE_MANAGE',
  // fund-management
  FUND_MANAGEMENT_MAIN_PAGE = 'FUND_MANAGEMENT_MAIN_PAGE',
  FUND_MANAGEMENT_PROCESS_TRANSACTION = 'FUND_MANAGEMENT_PROCESS_TRANSACTION',
  FUND_MANAGEMENT_VIEW_DETAILS = 'FUND_MANAGEMENT_VIEW_DETAILS',
  FUND_MANAGEMENT_EDIT_CANCEL = 'FUND_MANAGEMENT_EDIT_CANCEL',
  FUND_MANAGEMENT_WINDOW_EDIT = 'FUND_MANAGEMENT_WINDOW_EDIT',
  // role-management
  ROLE_MANAGEMENT_USERS_LIST = 'ROLE_MANAGEMENT_USERS_LIST',
  ROLE_MANAGEMENT_USERS_EDIT = 'ROLE_MANAGEMENT_USERS_EDIT',
  ROLE_MANAGEMENT_USERS_CREATE = 'ROLE_MANAGEMENT_USERS_CREATE',
  ROLE_MANAGEMENT_ROLES_LIST = 'ROLE_MANAGEMENT_ROLES_LIST',
  ROLE_MANAGEMENT_ROLES_EDIT = 'ROLE_MANAGEMENT_ROLES_EDIT',
  ROLE_MANAGEMENT_ROLES_CREATE = 'ROLE_MANAGEMENT_ROLES_CREATE',
  // crypto-delisting
  CRYPTO_DELISTING_LIST = 'CRYPTO_DELISTING_LIST',
  CRYPTO_DELISTING_REFUND = 'CRYPTO_DELISTING_REFUND',
  CRYPTO_DELISTING_HISTORY = 'CRYPTO_DELISTING_HISTORY',
  CRYPTO_DELISTING_HISTORY_DETAIL = 'CRYPTO_DELISTING_HISTORY_DETAIL',
  CRYPTO_DELISTING_HISTORY_DETAIL_DOWNLOAD = 'CRYPTO_DELISTING_HISTORY_DETAIL_DOWNLOAD',
  CRYPTO_DELISTING_HISTORY_DETAIL_RETRY = 'CRYPTO_DELISTING_HISTORY_DETAIL_RETRY',
  // crypto-transfer
  CRYPTO_TRANSFER_LIST = 'CRYPTO_TRANSFER_LIST',
  CRYPTO_TRANSFER_DEACTIVATION_HISTORY = 'CRYPTO_TRANSFER_DEACTIVATION_HISTORY',
  CRYPTO_TRANSFER_ACTIVATE = 'CRYPTO_TRANSFER_ACTIVATE',
  CRYPTO_TRANSFER_DEACTIVATE = 'CRYPTO_TRANSFER_DEACTIVATE',
  // locked-users
  LOCKED_USER_LIST = 'LOCKED_USER_LIST',
  LOCKED_USER_LIQUIDATION_HISTORY = 'LOCKED_USER_LIQUIDATION_HISTORY',
  LOCKED_USER_LIQUIDATE = 'LOCKED_USER_LIQUIDATE',
  // mission-management (gamification)
  GAMIFICATION_MISSION_LIST = 'GAMIFICATION_MISSION_LIST',
  GAMIFICATION_MISSION_DETAIL = 'GAMIFICATION_MISSION_DETAIL',
  GAMIFICATION_CREATE_MISSION = 'GAMIFICATION_CREATE_MISSION',
  GAMIFICATION_DELETE_MISSION = 'GAMIFICATION_DELETE_MISSION',
  GAMIFICATION_EDIT_REWARD_QUOTA = 'GAMIFICATION_EDIT_REWARD_QUOTA',
  GAMIFICATION_DISABLE_MISSION = 'GAMIFICATION_DISABLE_MISSION',
  GAMIFICATION_VIEW_MANUAL_REWARD_DISTRIBUTION = 'GAMIFICATION_VIEW_MANUAL_REWARD_DISTRIBUTION',
  GAMIFICATION_DOWNLOAD_MANUAL_REWARD_DISTRIBUTION = 'GAMIFICATION_DOWNLOAD_MANUAL_REWARD_DISTRIBUTION',
  GAMIFICATION_UPLOAD_MANUAL_REWARD_DISTRIBUTION = 'GAMIFICATION_UPLOAD_MANUAL_REWARD_DISTRIBUTION',
  GAMIFICATION_VIEW_NBTOP_REWARD = 'GAMIFICATION_VIEW_NBTOP_REWARD',
  GAMIFICATION_DOWNLOAD_NBTOP_REWARD = 'GAMIFICATION_DOWNLOAD_NBTOP_REWARD',
  GAMIFICATION_UPLOAD_NBTOP_REWARD = 'GAMIFICATION_UPLOAD_NBTOP_REWARD',
  // nbt-xchange
  NBT_XCHANGE_VIEW_TRANSACTION_HISTORY = 'NBT_XCHANGE_VIEW_TRANSACTION_HISTORY',
  NBT_XCHANGE_UPDATE_QUOTA_CONFIGURATION = 'NBT_XCHANGE_UPDATE_QUOTA_CONFIGURATION',
  NBT_XCHANGE_VIEW_QUOTA_CONFIGURATION = 'NBT_XCHANGE_VIEW_QUOTA_CONFIGURATION',
  // crypto wallet
  OPS_TRANSACTION_LIST = 'CRYPTO_WALLET_OPS_TRANSACTION_LIST',
  CRYPTO_WALLET_OPS_ACTION = 'CRYPTO_WALLET_OPS_ACTION',
  CRYPTO_WALLET_KYT_ACTION = 'CRYPTO_WALLET_KYT_ACTION',
  RECOVERY_TRANSACTION_LIST = 'CRYPTO_WALLET_RECOVERY_TRANSACTION_LIST',
  FINANCE_TRANSACTION_LIST = 'CRYPTO_WALLET_FINANCE_TRANSACTION_LIST',
  ASSET_SETTINGS = 'CRYPTO_WALLET_ASSET_SETTINGS',
  ASSET_SETTINGS_PARAMETER = 'CRYPTO_WALLET_ASSET_PARAMETER_SETTING',
  ASSET_SETTINGS_WITHDRAWAL = 'CRYPTO_WALLET_ASSET_WITHDRAWAL_SETTING',
  CRYPTO_WALLET_FORCE_SWEEP = 'CRYPTO_WALLET_FORCE_SWEEP',
  CRYPTO_WALLET_FORCE_SWEEPING = 'CRYPTO_WALLET_FORCE_SWEEPING',
  CRYPTO_WALLET_FORCE_SWEEP_HISTORY_LIST = 'CRYPTO_WALLET_FORCE_SWEEP_HISTORY_LIST',
  CRYPTO_WALLET_ADD_COIN = 'CRYPTO_WALLET_ADD_COIN',
  WITHDRAW_APPROVAL_LIST = 'WITHDRAW_APPROVAL_LIST',
  WITHDRAW_APPROVAL_HISTORY = 'WITHDRAW_APPROVAL_HISTORY',
  WITHDRAW_APPROVAL_APPROVE = 'WITHDRAW_APPROVAL_APPROVE',
  WITHDRAW_APPROVAL_REJECT = 'WITHDRAW_APPROVAL_REJECT',
  AUTO_REBALANCING_SETTING = 'CRYPTO_WALLET_AUTO_REBALANCING_SETTING',
  // recon-panel
  RECON_PANEL_LIST = 'RECON_PANEL_LIST',
  RECON_PANEL_DETAIL = 'RECON_PANEL_DETAIL',
  RECON_PANEL_DOWNLOAD_DETAIL = 'RECON_PANEL_DOWNLOAD_DETAIL',
  RECON_PANEL_DOWNLOAD_RESULT = 'RECON_PANEL_DOWNLOAD_RESULT',
  RECON_PANEL_DOWNLOAD_REPORT = 'RECON_PANEL_DOWNLOAD_REPORT',
  RECON_PANEL_UPLOAD_REPORT = 'RECON_PANEL_UPLOAD_REPORT',
  RECON_PANEL_APPROVE_OR_REJECT = 'RECON_PANEL_APPROVE_OR_REJECT',
  // referral-program
  REFERRAL_PROGRAM_LIST = 'REFERRAL_PROGRAM_LIST',
  REFERRAL_PROGRAM_DETAIL_VIEW = 'REFERRAL_PROGRAM_DETAIL_VIEW',
  REFERRAL_PROGRAM_EDIT = 'REFERRAL_PROGRAM_EDIT',
  // referral multiplier
  REFERRAL_MULTIPLIER_LIST = 'REFERRAL_MULTIPLIER_LIST',
  REFERRAL_MULTIPLIER_EDIT_DETAIL = 'REFERRAL_MULTIPLIER_EDIT_DETAIL',
  REFERRAL_MULTIPLIER_EDIT_LIST = 'REFERRAL_MULTIPLIER_EDIT_LIST',
  REFERRAL_MULTIPLIER_TOGGLE = 'REFERRAL_MULTIPLIER_TOGGLE',
  // stacking-management
  STACKING_MANAGEMENT_LIST = 'STACKING_MANAGEMENT_LIST',
  STACKING_MANAGEMENT_REACTIVATE = 'STACKING_MANAGEMENT_REACTIVATE',
  // toast-message
  TOAST_MESSAGE_MAIN_PAGE = 'TOAST_MESSAGE_MAIN_PAGE',
  TOAST_MESSAGE_EDIT = 'TOAST_MESSAGE_EDIT',
  TOAST_MESSAGE_REMOVE = 'TOAST_MESSAGE_REMOVE',
  // voucher
  VOUCHER_LIST = 'VOUCHER_LIST',
  VOUCHER_DETAIL = 'VOUCHER_DETAIL',
  VOUCHER_CREATE = 'VOUCHER_CREATE',
  VOUCHER_EDIT = 'VOUCHER_EDIT',
  VOUCHER_FORCE_END = 'VOUCHER_FORCE_END',
  // Raffle
  RAFFLE_LIST = 'RAFFLE_LIST',
  RAFFLE_CREATION = 'RAFFLE_CREATION',
  RAFFLE_DETAIL = 'RAFFLE_DETAIL',
  RAFFLE_EDIT = 'RAFFLE_EDIT',
  RAFFLE_DELETE = 'RAFFLE_DELETE',
  RAFFLE_FORCE_END = 'RAFFLE_FORCE_END',
  RAFFLE_DOWNLOAD_SUMMARY = 'RAFFLE_DOWNLOAD_SUMMARY',
  RAFFLE_DRAW = 'RAFFLE_DRAW',
  RAFFLE_WINNER_ANNOUNCEMENT = 'RAFFLE_WINNER_ANNOUNCEMENT',
  // poll-management
  POLL_MANAGEMENT_LIST = 'POLL_MANAGEMENT_LIST',
  POLL_MANAGEMENT_END_POLL = 'POLL_MANAGEMENT_END_POLL',
  POLL_MANAGEMENT_CREATE_POLL = 'POLL_MANAGEMENT_CREATE_POLL',
  // nbt-tracker
  NBT_TRACKER_LIST = 'NBT_TRACKER_LIST',
  // mutual-fund
  MUTUAL_FUND_LIST = 'MUTUAL_FUND_LIST',
  MUTUAL_FUND_DETAIL = 'MUTUAL_FUND_DETAIL',
  MUTUAL_FUND_UPDATE_PAYMENT_STATUS = 'MUTUAL_FUND_UPDATE_PAYMENT_STATUS',
  MUTUAL_FUND_DOWNLOAD = 'MUTUAL_FUND_DOWNLOAD',
  MUTUAL_FUND_AUDIT_LOG = 'MUTUAL_FUND_AUDIT_LOG',
  MUTUAL_FUND_SETTLEMENT_LIST = 'MUTUAL_FUND_SETTLEMENT_LIST',
  MUTUAL_FUND_SETTLEMENT_DOWNLOAD = 'MUTUAL_FUND_SETTLEMENT_DOWNLOAD',
  // carousel-config
  CAROUSEL_CONFIG_LIST = 'CAROUSEL_CONFIG_LIST',
  CAROUSEL_CONFIG_REORDER = 'CAROUSEL_CONFIG_REORDER',
  CAROUSEL_CONFIG_ACTIVATION = 'CAROUSEL_CONFIG_ACTIVATION',
  CAROUSEL_CONFIG_REMOVE = 'CAROUSEL_CONFIG_REMOVE',
  CAROUSEL_CONFIG_EDIT = 'CAROUSEL_CONFIG_EDIT',
  CAROUSEL_CONFIG_ADD = 'CAROUSEL_CONFIG_ADD',
  // global-stock-copy-messages
  GLOBAL_STOCK_COPY_MAIN_CARD = 'GLOBAL_STOCK_COPY_MAIN_CARD',
  GLOBAL_STOCK_COPY_MAIN_CARD_EDIT = 'GLOBAL_STOCK_COPY_MAIN_CARD_EDIT',
  GLOBAL_STOCK_COPY_US_INDICES_VIEW = 'GLOBAL_STOCK_COPY_US_INDICES_VIEW',
  GLOBAL_STOCK_COPY_US_INDICES_EDIT = 'GLOBAL_STOCK_COPY_US_INDICES_EDIT',
  GLOBAL_STOCK_COPY_US_COMPANY_LIST_VIEW = 'GLOBAL_STOCK_COPY_US_COMPANY_LIST_VIEW',
  GLOBAL_STOCK_COPY_US_COMPANY_LIST_EDIT = 'GLOBAL_STOCK_COPY_US_COMPANY_LIST_EDIT',
  GLOBAL_STOCK_NON_PALN_ASSETS_VIEW = 'GLOBAL_STOCK_NON_PALN_ASSETS_VIEW',
  GLOBAL_STOCK_NON_PALN_ASSETS_EDIT = 'GLOBAL_STOCK_NON_PALN_ASSETS_EDIT',
  // audit-log
  AUDIT_LOG_LIST = 'AUDIT_LOG_LIST',
  AUDIT_LOG_DETAILS = 'AUDIT_LOG_DETAILS',
  // audit-log
  GIFT_CARD_LIST = 'GIFT_CARD_LIST',
  GIFT_CARD_CREATE = 'GIFT_CARD_CREATE',
  GIFT_CARD_DETAIL = 'GIFT_CARD_DETAIL',
  // manual-kyc
  MANUAL_KYC_EXISTING_USER = 'MANUAL_KYC_EXISTING_USER',
  MANUAL_KYC_NEW_USER = 'MANUAL_KYC_NEW_USER',
  MANUAL_KYC_VERIFY_KYC_DATA = 'MANUAL_KYC_VERIFY_KYC_DATA',
  // LOCKED_STAKING
  LOCKED_STAKING_DOWNLOAD_CSV_USER_HOLDINGS = 'LOCKED_STAKING_DOWNLOAD_CSV_USER_HOLDINGS',
  LOCKED_STAKING_VIEW_PROGRAM_CONFIGURATION = 'LOCKED_STAKING_VIEW_PROGRAM_CONFIGURATION',
  LOCKED_STAKING_VIEW_USER_HOLDINGS = 'LOCKED_STAKING_VIEW_USER_HOLDINGS',
  LOCKED_STAKING_EDIT_PROGRAM_CONFIGURATION = 'LOCKED_STAKING_EDIT_PROGRAM_CONFIGURATION',
  LOCKED_STAKING_TERMINATE_HOLDINGS = 'LOCKED_STAKING_TERMINATE_HOLDINGS',
  LOCKED_STAKING_VIEW_DETAILS_HOLDINGS = 'LOCKED_STAKING_VIEW_DETAILS_HOLDINGS',
  LOCKED_STAKING_ADD_NEW_LOCK = 'LOCKED_STAKING_ADD_NEW_LOCK',
  //visibility-config
  VISIBILITY_CONFIG_LIST = 'VISIBILITY_CONFIG_LIST',
  VISIBILITY_CONFIG_EDIT = 'VISIBILITY_CONFIG_EDIT',
  //coin-listing
  NEW_COIN_LISTING_LIST = 'NEW_COIN_LISTING_LIST',
  NEW_COIN_LISTING_ENABLE = 'NEW_COIN_LISTING_ENABLE',
  NEW_COIN_LISTING_HISTORY = 'NEW_COIN_LISTING_HISTORY',
  // fraud-mitigation / hide referral reward
  RISK_MITIGATION_RESULTS = 'RISK_MITIGATION_RESULTS',
  RISK_MITIGATION_MONITORING = 'RISK_MITIGATION_MONITORING',
  RISK_MITIGATION_UPLOAD_BUTTON = 'RISK_MITIGATION_UPLOAD_BUTTON',
  RISK_MITIGATION_EDIT = 'RISK_MITIGATION_EDIT',
  //stock-logo-management
  LOGO_MANAGEMENT_MAIN_PAGE = 'LOGO_MANAGEMENT_MAIN_PAGE',
  LOGO_MANAGEMENT_EDIT_SOURCE = 'LOGO_MANAGEMENT_EDIT_SOURCE',
  LOGO_MANAGEMENT_EDIT_ASSET_STATUS = 'LOGO_MANAGEMENT_EDIT_ASSET_STATUS',
  LOGO_MANAGEMENT_SYNC_ALPACA_STATUS = 'LOGO_MANAGEMENT_SYNC_ALPACA_STATUS',
  //dormant-account-management
  DORMANT_ACCOUNT_MANAGEMENT_LIST = 'DORMANT_ACCOUNT_MANAGEMENT_LIST',
  DORMANT_ACCOUNT_MANAGEMENT_BULK_TURN_ON_AND_OFF_MONITORING = 'DORMANT_ACCOUNT_MANAGEMENT_BULK_TURN_ON_AND_OFF_MONITORING',
  DORMANT_ACCOUNT_MANAGEMENT_DOWNLOAD_LIST = 'DORMANT_ACCOUNT_MANAGEMENT_DOWNLOAD_LIST',
  //social
  SOCIAL_POST_VIEW = 'SOCIAL_POST_VIEW',
  SOCIAL_BAN = 'SOCIAL_BAN',
  //Global & Coin Listing
  GLOBAL_COINS_LIST_GLOBAL = 'GLOBAL_COINS_LIST_GLOBAL',
  GLOBAL_COINS_LIST_COINS = 'GLOBAL_COINS_LIST_COINS',
  GLOBAL_COINS_UPDATE_GLOBAL = 'GLOBAL_COINS_UPDATE_GLOBAL',
  GLOBAL_COINS_CHANGE_COINS = 'GLOBAL_COINS_CHANGE_COINS',
  GLOBAL_COINS_UPLOAD_COINS = 'GLOBAL_COINS_UPLOAD_COINS',
  GLOBAL_COINS_DOWNLOAD_COINS = 'GLOBAL_COINS_DOWNLOAD_COINS',
  GLOBAL_COINS_DISABLE_BUY_SELL = 'GLOBAL_COINS_DISABLE_BUY_SELL',
  // Nano Card
  NANO_CARD_PORTAL_LIST = 'NANO_CARD_PORTAL_LIST',
  NANO_CARD_UPLOAD_ADDITIONAL_CHARGE_PORTAL = 'NANO_CARD_UPLOAD_ADDITIONAL_CHARGE_PORTAL',
  NANO_CARD_PORTAL_DETAIL_VIEW = 'NANO_CARD_PORTAL_DETAIL_VIEW',
  NANO_CARD_PORTAL_UPLOAD_TRANSACTION_DETAIL = 'NANO_CARD_PORTAL_UPLOAD_TRANSACTION_DETAIL',
  NANO_CARD_VIEW_PAYMENT_TRACKER = 'NANO_CARD_VIEW_PAYMENT_TRACKER',
  NANO_CARD_PAYMENT_TRACKER_DETAIL_VIEW = 'NANO_CARD_PAYMENT_TRACKER_DETAIL_VIEW',
  NANO_CARD_REMINDER_PAYMENT_TRACKER = 'NANO_CARD_REMINDER_PAYMENT_TRACKER',
  NANO_CARD_VIEW_APPLICATION_LIST = 'NANO_CARD_VIEW_APPLICATION_LIST',
  NANO_CARD_UPLOAD_APPROVE_APPLICATION_LIST = 'NANO_CARD_UPLOAD_APPROVE_APPLICATION_LIST',
  NANO_CARD_UPLOAD_REJECT_APPLICATION_LIST = 'NANO_CARD_UPLOAD_REJECT_APPLICATION_LIST',
  NANO_CARD_APPLICATION_LIST_DETAIL_VIEW = 'NANO_CARD_APPLICATION_LIST_DETAIL_VIEW',
  // dynamic config management
  DYNAMIC_CONFIGS_DETAILS = 'DYNAMIC_CONFIGS_DETAILS',
  DYNAMIC_CONFIGS_CREATE = 'DYNAMIC_CONFIGS_CREATE',
  DYNAMIC_CONFIGS_EDIT = 'DYNAMIC_CONFIGS_EDIT',
  DYNAMIC_CONFIGS_LIST = 'DYNAMIC_CONFIGS_LIST',
  DYNAMIC_CONFIGS_AUDIT_LOG = 'DYNAMIC_CONFIGS_AUDIT_LOG',
  // Nano Staking
  NANO_STAKING_TRANSACTION_DOWNLOAD = 'NANO_STAKING_TRANSACTION_DOWNLOAD',
  NANO_STAKING_TRANSACTION_LIST = 'NANO_STAKING_TRANSACTION_LIST',
  // NBT Stacking
  NBT_STAKING_VIEW_PROMOTION = 'NBT_STAKING_VIEW_PROMOTION',
  NBT_STAKING_EDIT_PROGRAM = 'NBT_STAKING_EDIT_PROGRAM',
  NBT_STAKING_EDIT_PROMOTION = 'NBT_STAKING_EDIT_PROMOTION',
  NBT_STAKING_CREATE_PROMOTION = 'NBT_STAKING_CREATE_PROMOTION',
  NBT_STAKING_LIST = 'NBT_STAKING_LIST',
  NBT_STAKING_CREATE_PROGRAM = 'NBT_STAKING_CREATE_PROGRAM',
  NBT_STAKING_VIEW_HISTORY = 'NBT_STAKING_VIEW_HISTORY',
  // Leaderboard
  LEADERBOARD_EDIT_EVENT = 'LEADERBOARD_EDIT_EVENT',
  LEADERBOARD_CREATE_EVENT = 'LEADERBOARD_CREATE_EVENT',
  LEADERBOARD_VIEW_DETAILS = 'LEADERBOARD_VIEW_DETAILS',
  LEADERBOARD_LIST = 'LEADERBOARD_LIST',
  LEADERBOARD_DELETE_EVENT = 'LEADERBOARD_DELETE_EVENT',
  // Info box
  INFO_BOX_VIEW = 'INFO_BOX_VIEW',
  INFO_BOX_ACTION = 'INFO_BOX_ACTION',
  // Market Insight
  MARKET_INSIGHT_PREVIEW = 'MARKET_INSIGHT_PREVIEW',
  MARKET_INSIGHT_PUBLISH = 'MARKET_INSIGHT_PUBLISH',
  // Relationship Manager
  RELATIONSHIP_MANAGER_RM_LIST = 'RELATIONSHIP_MANAGER_RM_LIST',
  RELATIONSHIP_MANAGER_RM_CREATE_RM = 'RELATIONSHIP_MANAGER_RM_CREATE_RM',
  RELATIONSHIP_MANAGER_ELITE_USER_LIST = 'RELATIONSHIP_MANAGER_ELITE_USER_LIST',
  RELATIONSHIP_MANAGER_RM_DETAIL = 'RELATIONSHIP_MANAGER_RM_DETAIL',
  RELATIONSHIP_MANAGER_RM_DETAIL_DEACTIVATE_ACTIVATE_RM = 'RELATIONSHIP_MANAGER_RM_DETAIL_DEACTIVATE_ACTIVATE_RM',
  RELATIONSHIP_MANAGER_RM_DETAIL_CHANGE_RM_IN_BULK = 'RELATIONSHIP_MANAGER_RM_DETAIL_CHANGE_RM_IN_BULK',
}
export const ROLE_TO_ROUTE_MAP = {
  [SUPER_ADMIN_ROLE]: '/user-management',
  [KYC_OPS_ROLE]: '/kyc',
  [KYC_SPV_ROLE]: '/kyc',
  [PRODUCT_BUSINESS_OPS]: '/stock-category',
  [SPECIALIST]: '/stock-category',
  [CUSTOMER_SERVICE_OPS]: '/user-management',
  [SUPPORT_ADMIN]: '/user-management',
  [FINANCE_OPS]: '/settlement',
  [FINANCE_ADMIN]: '/settlement',
  [CUSTOMER_OPS_SPV]: '/kyc',
  [TECH_TEAM]: '/dynamic-config-management',
  [GS_PRODUCT_TEAM]: '/stock-logo-management/logo-management',
  [SALES_KYC]: '/manual-kyc'
};
export const PAGE_PATH_TO_ROLE_MAPPING = {
  // dashboard
  '/dashboard': [SUPER_ADMIN_ROLE, SUPPORT_ADMIN, SPECIALIST],
  // settlement
  '/settlement': [SUPER_ADMIN_ROLE, FINANCE_OPS, FINANCE_ADMIN],
  // Global Stocks Config
  '/stocks-config': [SUPER_ADMIN_ROLE, SPECIALIST],
  '/stocks-config/calendar-config': [SUPER_ADMIN_ROLE, SPECIALIST],
  '/stocks-config/stock-sync-scheduler': [SPECIALIST, SUPER_ADMIN_ROLE],
  // waitlist
  '/waitlist-panel': [SUPER_ADMIN_ROLE, SPECIALIST, KYC_OPS_ROLE, KYC_SPV_ROLE]
};

// NB: Add permissions for each routes.
export const PAGE_PATH_TO_PERMISSION_MAPPING = {
  //menu-group
  '/admin-panel-setting-group': [ALL_PERMISSIONS.ROLE_MANAGEMENT_ROLES_LIST, ALL_PERMISSIONS.ROLE_MANAGEMENT_USERS_LIST],
  '/user-support-group': [ALL_PERMISSIONS.USER_MANAGEMENT_USER_LIST, ALL_PERMISSIONS.KYC_ADMIN_PANEL_LIST, ALL_PERMISSIONS.KYC_ADMIN_PANEL_SUMMARY, ALL_PERMISSIONS.MANUAL_KYC_EXISTING_USER, ALL_PERMISSIONS.MANUAL_KYC_NEW_USER, ALL_PERMISSIONS.MANUAL_KYC_VERIFY_KYC_DATA, ALL_PERMISSIONS.RISK_MITIGATION_MONITORING, ALL_PERMISSIONS.NANO_CARD_PORTAL_LIST, ALL_PERMISSIONS.INFO_BOX_VIEW, ALL_PERMISSIONS.DORMANT_ACCOUNT_MANAGEMENT_LIST],
  '/user-tiering-group': [ALL_PERMISSIONS.MARKET_INSIGHT_PREVIEW, ALL_PERMISSIONS.RELATIONSHIP_MANAGER_RM_LIST],
  '/finance-group': [ALL_PERMISSIONS.WITHDRAWAL_MANAGEMENT_LIST, ALL_PERMISSIONS.BALANCE_ADJUSTMENT_LIST, ALL_PERMISSIONS.FUND_MANAGEMENT_MAIN_PAGE, ALL_PERMISSIONS.RECON_PANEL_LIST, ALL_PERMISSIONS.CASH_OUT_LIST, ALL_PERMISSIONS.LOCKED_USER_LIST, ALL_PERMISSIONS.LOCKED_USER_LIQUIDATION_HISTORY],
  '/program-campaigns-group': [ALL_PERMISSIONS.AUCTION_MANAGEMENT_ONGOING_LIST, ALL_PERMISSIONS.AUCTION_MANAGEMENT_FEATURED_LIST, ALL_PERMISSIONS.AUCTION_MANAGEMENT_PAST_LIST, ALL_PERMISSIONS.REFERRAL_CODE_LIST, ALL_PERMISSIONS.REFERRAL_MULTIPLIER_LIST, ALL_PERMISSIONS.REFERRAL_PROGRAM_LIST, ALL_PERMISSIONS.GAMIFICATION_MISSION_LIST, ALL_PERMISSIONS.GAMIFICATION_VIEW_MANUAL_REWARD_DISTRIBUTION, ALL_PERMISSIONS.GAMIFICATION_VIEW_NBTOP_REWARD, ALL_PERMISSIONS.VOUCHER_LIST, ALL_PERMISSIONS.GIFT_CARD_LIST, ALL_PERMISSIONS.RAFFLE_LIST, ALL_PERMISSIONS.LEADERBOARD_LIST, ALL_PERMISSIONS.HOMEPAGE_BANNER_LIST, ALL_PERMISSIONS.POLL_MANAGEMENT_LIST],
  '/mutual-fund-group': [ALL_PERMISSIONS.MUTUAL_FUND_LIST, ALL_PERMISSIONS.MUTUAL_FUND_SETTLEMENT_LIST],
  '/liquidation-management-group': [ALL_PERMISSIONS.LOCKED_USER_LIST, ALL_PERMISSIONS.LOCKED_USER_LIQUIDATION_HISTORY],
  '/crypto-config-group': [ALL_PERMISSIONS.CRYPTO_CATEGORY_LIST, ALL_PERMISSIONS.STACKING_MANAGEMENT_LIST, ALL_PERMISSIONS.CRYPTO_TRANSFER_LIST, ALL_PERMISSIONS.CRYPTO_DELISTING_LIST, ALL_PERMISSIONS.CRYPTO_DELISTING_HISTORY, ALL_PERMISSIONS.LOCKED_STAKING_VIEW_USER_HOLDINGS, ALL_PERMISSIONS.LOCKED_STAKING_VIEW_PROGRAM_CONFIGURATION, ALL_PERMISSIONS.NEW_COIN_LISTING_LIST, ALL_PERMISSIONS.NEW_COIN_LISTING_HISTORY, ALL_PERMISSIONS.NBT_XCHANGE_VIEW_TRANSACTION_HISTORY, ALL_PERMISSIONS.NBT_XCHANGE_VIEW_QUOTA_CONFIGURATION, ALL_PERMISSIONS.NBT_TRACKER_LIST, ALL_PERMISSIONS.OPS_TRANSACTION_LIST, ALL_PERMISSIONS.FINANCE_TRANSACTION_LIST, ALL_PERMISSIONS.ASSET_SETTINGS, ALL_PERMISSIONS.ASSET_SETTINGS_PARAMETER, ALL_PERMISSIONS.ASSET_SETTINGS_WITHDRAWAL, ALL_PERMISSIONS.AUTO_REBALANCING_SETTING, ALL_PERMISSIONS.WITHDRAW_APPROVAL_LIST, ALL_PERMISSIONS.WITHDRAW_APPROVAL_HISTORY, ALL_PERMISSIONS.WITHDRAW_APPROVAL_APPROVE, ALL_PERMISSIONS.WITHDRAW_APPROVAL_REJECT, ALL_PERMISSIONS.GLOBAL_COINS_LIST_GLOBAL, ALL_PERMISSIONS.GLOBAL_COINS_LIST_COINS, ALL_PERMISSIONS.NBT_STAKING_LIST, ALL_PERMISSIONS.NBT_STAKING_VIEW_HISTORY, ALL_PERMISSIONS.NBT_STAKING_VIEW_PROMOTION],
  '/stocks-config-group': [ALL_PERMISSIONS.GLOBAL_STOCK_COPY_MAIN_CARD, ALL_PERMISSIONS.GLOBAL_STOCK_COPY_US_INDICES_VIEW, ALL_PERMISSIONS.GLOBAL_STOCK_COPY_US_COMPANY_LIST_VIEW, ALL_PERMISSIONS.VISIBILITY_CONFIG_LIST, ALL_PERMISSIONS.STOCK_CATEGORY_LIST, ALL_PERMISSIONS.LOGO_MANAGEMENT_MAIN_PAGE, ALL_PERMISSIONS.GLOBAL_STOCK_NON_PALN_ASSETS_VIEW],
  '/general-app-config-group': [ALL_PERMISSIONS.TRANSLATION_DOWNLOAD, ALL_PERMISSIONS.TRANSLATION_UPLOAD, ALL_PERMISSIONS.CAROUSEL_CONFIG_LIST, ALL_PERMISSIONS.TOAST_MESSAGE_MAIN_PAGE, ALL_PERMISSIONS.AUDIT_LOG_LIST, ALL_PERMISSIONS.AUDIT_LOG_DETAILS, ALL_PERMISSIONS.DYNAMIC_CONFIGS_LIST],
  '/social-config-group': [ALL_PERMISSIONS.SOCIAL_POST_VIEW],
  // auction-management
  '/auction': [ALL_PERMISSIONS.AUCTION_MANAGEMENT_ONGOING_LIST, ALL_PERMISSIONS.AUCTION_MANAGEMENT_FEATURED_LIST, ALL_PERMISSIONS.AUCTION_MANAGEMENT_PAST_LIST],
  '/auction/create-new-auction': [ALL_PERMISSIONS.AUCTION_MANAGEMENT_CREATE],
  '/auction/detail': [ALL_PERMISSIONS.AUCTION_MANAGEMENT_ONGOING_DETAIL, ALL_PERMISSIONS.AUCTION_MANAGEMENT_FEATURED_DETAIL, ALL_PERMISSIONS.AUCTION_MANAGEMENT_PAST_DETAIL],
  // balance adjustment
  '/balance-adjustment': [ALL_PERMISSIONS.BALANCE_ADJUSTMENT_LIST],
  '/balance-adjustment/form-adjustment': [ALL_PERMISSIONS.BALANCE_ADJUSTMENT_EDIT, ALL_PERMISSIONS.BALANCE_ADJUSTMENT_CREATE],
  '/balance-adjustment/view-details': [ALL_PERMISSIONS.BALANCE_ADJUSTMENT_DETAIL_VIEW],
  // banner
  '/banner': [ALL_PERMISSIONS.HOMEPAGE_BANNER_LIST],
  '/banner/form': [ALL_PERMISSIONS.HOMEPAGE_BANNER_ADD, ALL_PERMISSIONS.HOMEPAGE_BANNER_EDIT, ALL_PERMISSIONS.HOMEPAGE_BANNER_VIEW_DETAIL],
  // translation
  '/translation': [ALL_PERMISSIONS.TRANSLATION_DOWNLOAD, ALL_PERMISSIONS.TRANSLATION_UPLOAD],
  // stock-category
  '/stock-category': [ALL_PERMISSIONS.STOCK_CATEGORY_LIST],
  '/stock-category/form': [ALL_PERMISSIONS.STOCK_CATEGORY_ADD, ALL_PERMISSIONS.STOCK_CATEGORY_MANAGE],
  // cash-out
  '/cash-out': [ALL_PERMISSIONS.CASH_OUT_LIST],
  // crypto-config
  '/crypto-config/crypto-category': [ALL_PERMISSIONS.CRYPTO_CATEGORY_LIST],
  '/crypto-config/crypto-category/form': [ALL_PERMISSIONS.CRYPTO_CATEGORY_ADD, ALL_PERMISSIONS.CRYPTO_CATEGORY_MANAGE],
  // crypto-transfer
  '/crypto-config/crypto-transfer': [ALL_PERMISSIONS.CRYPTO_TRANSFER_LIST],
  // crypto-wallet
  '/crypto-config/crypto-wallet': [ALL_PERMISSIONS.OPS_TRANSACTION_LIST, ALL_PERMISSIONS.FINANCE_TRANSACTION_LIST],
  '/crypto-config/asset-settings': [ALL_PERMISSIONS.ASSET_SETTINGS, ALL_PERMISSIONS.ASSET_SETTINGS_PARAMETER, ALL_PERMISSIONS.ASSET_SETTINGS_WITHDRAWAL, ALL_PERMISSIONS.AUTO_REBALANCING_SETTING],
  '/crypto-config/asset-settings/edit': [ALL_PERMISSIONS.ASSET_SETTINGS, ALL_PERMISSIONS.ASSET_SETTINGS_PARAMETER, ALL_PERMISSIONS.ASSET_SETTINGS_WITHDRAWAL],
  '/crypto-config/asset-settings/force-sweep': [ALL_PERMISSIONS.CRYPTO_WALLET_FORCE_SWEEP, ALL_PERMISSIONS.CRYPTO_WALLET_FORCE_SWEEPING, ALL_PERMISSIONS.CRYPTO_WALLET_FORCE_SWEEP_HISTORY_LIST],
  '/crypto-config/asset-settings/force-sweep/history': [ALL_PERMISSIONS.CRYPTO_WALLET_FORCE_SWEEP, ALL_PERMISSIONS.CRYPTO_WALLET_FORCE_SWEEPING, ALL_PERMISSIONS.CRYPTO_WALLET_FORCE_SWEEP_HISTORY_LIST],
  '/crypto-config/asset-settings/add-coin-fireblocks': [ALL_PERMISSIONS.CRYPTO_WALLET_ADD_COIN],
  '/crypto-config/crypto-withdrawal': [ALL_PERMISSIONS.WITHDRAW_APPROVAL_LIST, ALL_PERMISSIONS.WITHDRAW_APPROVAL_HISTORY, ALL_PERMISSIONS.WITHDRAW_APPROVAL_APPROVE, ALL_PERMISSIONS.WITHDRAW_APPROVAL_REJECT],
  '/crypto-config/coin-listing': [ALL_PERMISSIONS.NEW_COIN_LISTING_LIST, ALL_PERMISSIONS.NEW_COIN_LISTING_HISTORY],
  '/crypto-config/coin-listing/detail': [ALL_PERMISSIONS.NEW_COIN_LISTING_LIST, ALL_PERMISSIONS.NEW_COIN_LISTING_HISTORY],
  '/crypto-config/nano-staking': [ALL_PERMISSIONS.NANO_STAKING_TRANSACTION_LIST, ALL_PERMISSIONS.NANO_STAKING_TRANSACTION_DOWNLOAD],
  // stacking-management
  '/crypto-config/stacking-management': [ALL_PERMISSIONS.STACKING_MANAGEMENT_LIST],
  // locked-staking
  '/crypto-config/locked-staking': [ALL_PERMISSIONS.LOCKED_STAKING_VIEW_USER_HOLDINGS, ALL_PERMISSIONS.LOCKED_STAKING_VIEW_PROGRAM_CONFIGURATION],
  // nbt-tracker
  '/crypto-config/nbt-tracker': [ALL_PERMISSIONS.NBT_TRACKER_LIST],
  // nbt-xchange
  '/crypto-config/nbt-xchange': [ALL_PERMISSIONS.NBT_XCHANGE_VIEW_TRANSACTION_HISTORY, ALL_PERMISSIONS.NBT_XCHANGE_VIEW_QUOTA_CONFIGURATION],
  // global-coin-config
  '/crypto-config/global-coin-config': [ALL_PERMISSIONS.GLOBAL_COINS_LIST_GLOBAL, ALL_PERMISSIONS.GLOBAL_COINS_LIST_COINS],
  //nbt-staking
  '/crypto-config/nbt-staking': [ALL_PERMISSIONS.NBT_STAKING_LIST, ALL_PERMISSIONS.NBT_STAKING_VIEW_HISTORY, ALL_PERMISSIONS.NBT_STAKING_VIEW_PROMOTION],
  '/crypto-config/nbt-staking/detail': [ALL_PERMISSIONS.NBT_STAKING_LIST, ALL_PERMISSIONS.NBT_STAKING_VIEW_HISTORY],
  // withdrawal-management
  '/withdrawal-management': [ALL_PERMISSIONS.WITHDRAWAL_MANAGEMENT_LIST],
  '/withdrawal-management/add-channel': [ALL_PERMISSIONS.WITHDRAWAL_MANAGEMENT_ADD],
  '/withdrawal-management/channel-details': [ALL_PERMISSIONS.WITHDRAWAL_MANAGEMENT_VIEW_DETAIL],
  '/withdrawal-management/edit-channel': [ALL_PERMISSIONS.WITHDRAWAL_MANAGEMENT_EDIT],
  '/withdrawal-management/edit-provider': [ALL_PERMISSIONS.WITHDRAWAL_MANAGEMENT_EDIT],
  '/withdrawal-management/provider-details': [ALL_PERMISSIONS.WITHDRAWAL_MANAGEMENT_VIEW_DETAIL],
  // user management
  '/user-management': [ALL_PERMISSIONS.USER_MANAGEMENT_USER_LIST],
  '/user-management/detail': [ALL_PERMISSIONS.USER_MANAGEMENT_VIEW_USER_HOLDING_DETAIL, ALL_PERMISSIONS.USER_MANAGEMENT_LIST_OF_TRANSACTION, ALL_PERMISSIONS.USER_MANAGEMENT_DETAILED_KYC_VIEW, ALL_PERMISSIONS.USER_MANAGEMENT_REWARD_HISTORY, ALL_PERMISSIONS.USER_MANAGEMENT_RISK_MONITORING],
  '/user-management/update-profile': [ALL_PERMISSIONS.USER_MANAGEMENT_UPDATE_PROFILE],
  // recon-panel
  '/recon-panel': [ALL_PERMISSIONS.RECON_PANEL_LIST],
  '/recon-panel/detail': [ALL_PERMISSIONS.RECON_PANEL_DETAIL, ALL_PERMISSIONS.RECON_PANEL_APPROVE_OR_REJECT],
  '/recon-panel/upload-report': [ALL_PERMISSIONS.RECON_PANEL_UPLOAD_REPORT],
  // referral-code
  '/referral-code': [ALL_PERMISSIONS.REFERRAL_CODE_LIST],
  // referral program
  '/referral-program': [ALL_PERMISSIONS.REFERRAL_PROGRAM_LIST],
  '/referral-program/configuration': [ALL_PERMISSIONS.REFERRAL_PROGRAM_EDIT],
  '/referral-program/detail': [ALL_PERMISSIONS.REFERRAL_PROGRAM_DETAIL_VIEW],
  // fund management
  '/fund-management': [ALL_PERMISSIONS.FUND_MANAGEMENT_MAIN_PAGE],
  '/fund-management/detail': [ALL_PERMISSIONS.FUND_MANAGEMENT_VIEW_DETAILS],
  // role management
  '/role-management': [ALL_PERMISSIONS.ROLE_MANAGEMENT_USERS_LIST, ALL_PERMISSIONS.ROLE_MANAGEMENT_ROLES_LIST],
  // kyc-admin-panel
  '/kyc': [ALL_PERMISSIONS.KYC_ADMIN_PANEL_LIST, ALL_PERMISSIONS.KYC_ADMIN_PANEL_SUMMARY],
  '/kyc/detail': [ALL_PERMISSIONS.KYC_ADMIN_PANEL_VIEW_DETAIL, ALL_PERMISSIONS.KYC_ADMIN_PANEL_KYC_HISTORY_LIST],
  '/dormant-management': [ALL_PERMISSIONS.DORMANT_ACCOUNT_MANAGEMENT_LIST, ALL_PERMISSIONS.DORMANT_ACCOUNT_MANAGEMENT_DOWNLOAD_LIST, ALL_PERMISSIONS.DORMANT_ACCOUNT_MANAGEMENT_BULK_TURN_ON_AND_OFF_MONITORING],
  // crypto-delisting
  '/crypto-config/crypto-delisting': [ALL_PERMISSIONS.CRYPTO_DELISTING_LIST, ALL_PERMISSIONS.CRYPTO_DELISTING_HISTORY],
  '/crypto-config/crypto-delisting/refund-details': [ALL_PERMISSIONS.CRYPTO_DELISTING_HISTORY_DETAIL],
  // liquidation management
  '/liquidation-management/user-level': [ALL_PERMISSIONS.LOCKED_USER_LIST, ALL_PERMISSIONS.LOCKED_USER_LIQUIDATION_HISTORY],
  '/liquidation-management/asset-level/crypto': [ALL_PERMISSIONS.LOCKED_USER_LIST, ALL_PERMISSIONS.LOCKED_USER_LIQUIDATION_HISTORY],
  '/liquidation-management/asset-level/crypto/detail': [ALL_PERMISSIONS.LOCKED_USER_LIST],
  // mission management (gamification)
  '/mission-management': [ALL_PERMISSIONS.GAMIFICATION_MISSION_LIST, ALL_PERMISSIONS.GAMIFICATION_VIEW_MANUAL_REWARD_DISTRIBUTION, ALL_PERMISSIONS.GAMIFICATION_VIEW_NBTOP_REWARD],
  '/mission-management/create-new-mission': [ALL_PERMISSIONS.GAMIFICATION_CREATE_MISSION],
  '/mission-management/create-new-collective-mission': [ALL_PERMISSIONS.GAMIFICATION_CREATE_MISSION],
  '/mission-management/detail': [ALL_PERMISSIONS.GAMIFICATION_MISSION_DETAIL],
  '/mission-management/collective-mission': [ALL_PERMISSIONS.GAMIFICATION_MISSION_DETAIL],
  // poll-management
  '/poll-management': [ALL_PERMISSIONS.POLL_MANAGEMENT_LIST],
  // referral multiplier
  '/referral-multiplier': [ALL_PERMISSIONS.REFERRAL_MULTIPLIER_LIST],
  // toast-message
  '/toast-message': [ALL_PERMISSIONS.TOAST_MESSAGE_MAIN_PAGE],
  // giftcard
  '/giftcard/list': [ALL_PERMISSIONS.GIFT_CARD_LIST],
  '/giftcard/detail': [ALL_PERMISSIONS.GIFT_CARD_DETAIL],
  // voucher
  '/voucher/list': [ALL_PERMISSIONS.VOUCHER_LIST],
  '/voucher/create': [ALL_PERMISSIONS.VOUCHER_CREATE],
  '/voucher/detail': [ALL_PERMISSIONS.VOUCHER_DETAIL],
  '/voucher/edit': [ALL_PERMISSIONS.VOUCHER_EDIT],
  // raffle
  '/raffle': [ALL_PERMISSIONS.RAFFLE_LIST],
  '/raffle/create': [ALL_PERMISSIONS.RAFFLE_CREATION],
  '/raffle/detail': [ALL_PERMISSIONS.RAFFLE_DETAIL],
  '/raffle/edit': [ALL_PERMISSIONS.RAFFLE_EDIT],
  // market-insight
  '/market-insight': [ALL_PERMISSIONS.MARKET_INSIGHT_PREVIEW],
  // relationship-manager
  '/relationship-manager': [ALL_PERMISSIONS.RELATIONSHIP_MANAGER_RM_LIST, ALL_PERMISSIONS.RELATIONSHIP_MANAGER_ELITE_USER_LIST],
  '/relationship-manager/detail': [ALL_PERMISSIONS.RELATIONSHIP_MANAGER_RM_DETAIL],
  // mutual-fund
  '/mutual-fund': [ALL_PERMISSIONS.MUTUAL_FUND_LIST, ALL_PERMISSIONS.MUTUAL_FUND_SETTLEMENT_LIST],
  '/mutual-fund/details': [ALL_PERMISSIONS.MUTUAL_FUND_DETAIL],
  // carousel-config
  '/stocks-config/carousel-config': [ALL_PERMISSIONS.CAROUSEL_CONFIG_LIST],
  // global-stock-copy
  '/stocks-config/global-stock-messages': [ALL_PERMISSIONS.GLOBAL_STOCK_COPY_MAIN_CARD, ALL_PERMISSIONS.GLOBAL_STOCK_COPY_US_INDICES_VIEW, ALL_PERMISSIONS.GLOBAL_STOCK_COPY_US_COMPANY_LIST_VIEW, ALL_PERMISSIONS.GLOBAL_STOCK_NON_PALN_ASSETS_VIEW],
  // visibility config
  '/stocks-config/visibility-config': [ALL_PERMISSIONS.VISIBILITY_CONFIG_LIST],
  // audit-log
  '/audit-log': [ALL_PERMISSIONS.AUDIT_LOG_LIST],
  '/audit-log/detail': [ALL_PERMISSIONS.AUDIT_LOG_DETAILS],
  // manual-kyc
  '/manual-kyc': [ALL_PERMISSIONS.MANUAL_KYC_EXISTING_USER, ALL_PERMISSIONS.MANUAL_KYC_NEW_USER, ALL_PERMISSIONS.MANUAL_KYC_VERIFY_KYC_DATA],
  // fraud-mitigation
  '/fraud-mitigation': [ALL_PERMISSIONS.RISK_MITIGATION_MONITORING],
  '/fraud-mitigation/validation': [ALL_PERMISSIONS.RISK_MITIGATION_MONITORING, ALL_PERMISSIONS.RISK_MITIGATION_UPLOAD_BUTTON],
  '/fraud-mitigation/detail': [ALL_PERMISSIONS.RISK_MITIGATION_MONITORING, ALL_PERMISSIONS.RISK_MITIGATION_UPLOAD_BUTTON, ALL_PERMISSIONS.RISK_MITIGATION_EDIT, ALL_PERMISSIONS.RISK_MITIGATION_RESULTS],
  // social
  '/social/user-monitoring': [ALL_PERMISSIONS.SOCIAL_POST_VIEW],
  '/social/user-monitoring/user-detail': [ALL_PERMISSIONS.SOCIAL_POST_VIEW],
  //logo-management
  '/stock-logo-management/logo-management': [ALL_PERMISSIONS.LOGO_MANAGEMENT_MAIN_PAGE],
  // Nano card
  '/nano-card': [ALL_PERMISSIONS.NANO_CARD_PORTAL_LIST],
  '/nano-card/detail': [ALL_PERMISSIONS.NANO_CARD_UPLOAD_ADDITIONAL_CHARGE_PORTAL, ALL_PERMISSIONS.NANO_CARD_PORTAL_DETAIL_VIEW, ALL_PERMISSIONS.NANO_CARD_PORTAL_UPLOAD_TRANSACTION_DETAIL, ALL_PERMISSIONS.NANO_CARD_VIEW_PAYMENT_TRACKER, ALL_PERMISSIONS.NANO_CARD_PAYMENT_TRACKER_DETAIL_VIEW, ALL_PERMISSIONS.NANO_CARD_REMINDER_PAYMENT_TRACKER, ALL_PERMISSIONS.NANO_CARD_VIEW_APPLICATION_LIST, ALL_PERMISSIONS.NANO_CARD_UPLOAD_APPROVE_APPLICATION_LIST, ALL_PERMISSIONS.NANO_CARD_UPLOAD_REJECT_APPLICATION_LIST, ALL_PERMISSIONS.NANO_CARD_APPLICATION_LIST_DETAIL_VIEW],
  // dynamic config management
  '/dynamic-config-management': [ALL_PERMISSIONS.DYNAMIC_CONFIGS_LIST],
  '/dynamic-config-management/create-dynamic-config': [ALL_PERMISSIONS.DYNAMIC_CONFIGS_CREATE],
  '/dynamic-config-management/detail': [ALL_PERMISSIONS.DYNAMIC_CONFIGS_DETAILS],
  '/dynamic-config-management/view-audit-log': [ALL_PERMISSIONS.DYNAMIC_CONFIGS_AUDIT_LOG],
  '/dynamic-config-management/edit-dynamic-config': [ALL_PERMISSIONS.DYNAMIC_CONFIGS_EDIT],
  // leaderboard
  '/leaderboard': [ALL_PERMISSIONS.LEADERBOARD_LIST],
  '/leaderboard/create': [ALL_PERMISSIONS.LEADERBOARD_CREATE_EVENT],
  '/leaderboard/detail': [ALL_PERMISSIONS.LEADERBOARD_VIEW_DETAILS],
  // info box
  '/info-box': [ALL_PERMISSIONS.INFO_BOX_VIEW]
};

// NB: Add proper route to map default permission for a feature.
export const DEFAULT_PERMISSION_TO_ROUTE_MAP = {
  // user management
  [ALL_PERMISSIONS.USER_MANAGEMENT_USER_LIST]: '/user-management',
  // kyc-admin-panel
  [ALL_PERMISSIONS.KYC_ADMIN_PANEL_LIST]: '/kyc',
  [ALL_PERMISSIONS.KYC_ADMIN_PANEL_SUMMARY]: '/kyc',
  // role management
  [ALL_PERMISSIONS.ROLE_MANAGEMENT_USERS_LIST]: '/role-management',
  [ALL_PERMISSIONS.ROLE_MANAGEMENT_ROLES_LIST]: '/role-management',
  // balance-adjustment
  [ALL_PERMISSIONS.BALANCE_ADJUSTMENT_LIST]: '/balance-adjustment',
  // banner
  [ALL_PERMISSIONS.HOMEPAGE_BANNER_LIST]: '/banner',
  // translation
  [ALL_PERMISSIONS.TRANSLATION_DOWNLOAD]: '/translation',
  [ALL_PERMISSIONS.TRANSLATION_UPLOAD]: '/translation',
  // stock-category
  [ALL_PERMISSIONS.STOCK_CATEGORY_LIST]: '/stock-category',
  // crypto category
  [ALL_PERMISSIONS.CRYPTO_CATEGORY_LIST]: '/crypto-config/crypto-category',
  // cash-out
  [ALL_PERMISSIONS.CASH_OUT_LIST]: '/cash-out',
  // crypto-transfer
  [ALL_PERMISSIONS.CRYPTO_TRANSFER_LIST]: '/crypto-config/crypto-transfer',
  // crypto-wallet
  [ALL_PERMISSIONS.OPS_TRANSACTION_LIST]: '/crypto-config/crypto-wallet',
  [ALL_PERMISSIONS.FINANCE_TRANSACTION_LIST]: '/crypto-config/crypto-wallet',
  [ALL_PERMISSIONS.WITHDRAW_APPROVAL_LIST]: '/crypto-config/crypto-withdrawal',
  // crypto-wallet
  [ALL_PERMISSIONS.ASSET_SETTINGS]: '/crypto-config/asset-settings',
  // crypto-delisting
  [ALL_PERMISSIONS.CRYPTO_DELISTING_LIST]: '/crypto-config/crypto-delisting',
  [ALL_PERMISSIONS.CRYPTO_DELISTING_HISTORY]: '/crypto-config/crypto-delisting',
  [ALL_PERMISSIONS.NBT_XCHANGE_VIEW_TRANSACTION_HISTORY]: '/crypto-config/nbt-xchange',
  [ALL_PERMISSIONS.NBT_XCHANGE_VIEW_QUOTA_CONFIGURATION]: '/crypto-config/nbt-xchange',
  [ALL_PERMISSIONS.LOCKED_STAKING_VIEW_USER_HOLDINGS]: '/crypto-config/locked-staking',
  [ALL_PERMISSIONS.LOCKED_STAKING_VIEW_PROGRAM_CONFIGURATION]: '/crypto-config/locked-staking',
  // nano-staking
  [ALL_PERMISSIONS.NANO_STAKING_TRANSACTION_LIST]: '/crypto-config/nano-staking',
  // auction-management
  [ALL_PERMISSIONS.AUCTION_MANAGEMENT_ONGOING_LIST]: '/auction',
  [ALL_PERMISSIONS.AUCTION_MANAGEMENT_FEATURED_LIST]: '/auction',
  [ALL_PERMISSIONS.AUCTION_MANAGEMENT_PAST_LIST]: '/auction',
  // withdrawal-management
  [ALL_PERMISSIONS.WITHDRAWAL_MANAGEMENT_LIST]: '/withdrawal-management',
  // recon-panel
  [ALL_PERMISSIONS.RECON_PANEL_LIST]: '/recon-panel',
  // referral-code
  [ALL_PERMISSIONS.REFERRAL_CODE_LIST]: '/referral-code',
  // referral program
  [ALL_PERMISSIONS.REFERRAL_PROGRAM_LIST]: '/referral-program',
  // referral multiplier
  [ALL_PERMISSIONS.REFERRAL_MULTIPLIER_LIST]: '/referral-multiplier',
  // fund management
  [ALL_PERMISSIONS.FUND_MANAGEMENT_MAIN_PAGE]: '/fund-management',
  // liquidation management
  [ALL_PERMISSIONS.LOCKED_USER_LIST]: '/liquidation-management/user-level',
  [ALL_PERMISSIONS.LOCKED_USER_LIQUIDATION_HISTORY]: '/liquidation-management/user-level',
  // mission-management (gamification)
  [ALL_PERMISSIONS.GAMIFICATION_MISSION_LIST]: '/mission-management',
  [ALL_PERMISSIONS.GAMIFICATION_VIEW_MANUAL_REWARD_DISTRIBUTION]: '/mission-management',
  [ALL_PERMISSIONS.GAMIFICATION_VIEW_NBTOP_REWARD]: '/mission-management',
  // stacking-management
  [ALL_PERMISSIONS.STACKING_MANAGEMENT_LIST]: '/crypto-config/stacking-management',
  // toast-message
  [ALL_PERMISSIONS.TOAST_MESSAGE_MAIN_PAGE]: '/toast-message',
  // voucher
  [ALL_PERMISSIONS.VOUCHER_LIST]: '/voucher/list',
  // nbt-tracker
  [ALL_PERMISSIONS.NBT_TRACKER_LIST]: '/crypto-config/nbt-tracker',
  // poll-management
  [ALL_PERMISSIONS.POLL_MANAGEMENT_LIST]: '/poll-management',
  // mutual-fund
  [ALL_PERMISSIONS.MUTUAL_FUND_LIST]: '/mutual-fund',
  [ALL_PERMISSIONS.MUTUAL_FUND_SETTLEMENT_LIST]: '/mutual-fund',
  // carousel-config
  [ALL_PERMISSIONS.CAROUSEL_CONFIG_LIST]: '/stocks-config/carousel-config',
  // global-stock-copy
  [ALL_PERMISSIONS.GLOBAL_STOCK_COPY_MAIN_CARD]: '/stocks-config/global-stock-messages',
  [ALL_PERMISSIONS.GLOBAL_STOCK_COPY_US_INDICES_VIEW]: '/stocks-config/global-stock-messages',
  [ALL_PERMISSIONS.GLOBAL_STOCK_COPY_US_COMPANY_LIST_VIEW]: '/stocks-config/global-stock-messages',
  [ALL_PERMISSIONS.GLOBAL_STOCK_NON_PALN_ASSETS_VIEW]: '/stocks-config/global-stock-messages',
  //giftcard
  [ALL_PERMISSIONS.GIFT_CARD_LIST]: '/giftcard/list',
  // audit-log
  [ALL_PERMISSIONS.AUDIT_LOG_LIST]: '/audit-log',
  // raffle
  [ALL_PERMISSIONS.RAFFLE_LIST]: '/raffle',
  // market-insight
  [ALL_PERMISSIONS.MARKET_INSIGHT_PREVIEW]: '/market-insight',
  // relationship-manager
  [ALL_PERMISSIONS.RELATIONSHIP_MANAGER_RM_LIST]: '/relationship-manager',
  // manual kyc
  [ALL_PERMISSIONS.MANUAL_KYC_EXISTING_USER]: '/manual-kyc',
  [ALL_PERMISSIONS.MANUAL_KYC_NEW_USER]: '/manual-kyc',
  [ALL_PERMISSIONS.MANUAL_KYC_VERIFY_KYC_DATA]: '/manual-kyc',
  // fraud mitigation
  [ALL_PERMISSIONS.RISK_MITIGATION_MONITORING]: '/fraud-mitigation',
  // visibility config
  [ALL_PERMISSIONS.VISIBILITY_CONFIG_LIST]: '/stocks-config/visibility-config',
  //coin-listing
  [ALL_PERMISSIONS.NEW_COIN_LISTING_LIST]: '/crypto-config/coin-listing',
  [ALL_PERMISSIONS.NEW_COIN_LISTING_HISTORY]: '/crypto-config/coin-listing',
  //social
  [ALL_PERMISSIONS.SOCIAL_POST_VIEW]: '/social/user-monitoring',
  //logo-management
  [ALL_PERMISSIONS.LOGO_MANAGEMENT_MAIN_PAGE]: '/stock-logo-management/logo-management',
  //global-coin-config
  [ALL_PERMISSIONS.GLOBAL_COINS_LIST_GLOBAL]: '/crypto-config/global-coin-config',
  [ALL_PERMISSIONS.GLOBAL_COINS_LIST_COINS]: '/crypto-config/global-coin-config',
  //nano card
  [ALL_PERMISSIONS.NANO_CARD_PORTAL_LIST]: '/nano-card',
  // dynamic config management
  [ALL_PERMISSIONS.DYNAMIC_CONFIGS_LIST]: '/dynamic-config-management',
  [ALL_PERMISSIONS.NBT_STAKING_LIST]: '/crypto-config/nbt-staking',
  [ALL_PERMISSIONS.NBT_STAKING_VIEW_HISTORY]: '/crypto-config/nbt-staking',
  [ALL_PERMISSIONS.NBT_STAKING_VIEW_PROMOTION]: '/crypto-config/nbt-staking',
  // leaderboard
  [ALL_PERMISSIONS.LEADERBOARD_LIST]: '/leaderboard',
  [ALL_PERMISSIONS.INFO_BOX_VIEW]: '/info-box'
};